import compact from "lodash/compact";
import sortBy from "lodash/sortBy";
import { sub } from "date-fns/sub";
import { dateTimeLabelFormats } from "common/components/charts/highchartsConfig";

import {
  TopicDashboardChartSectionConfig,
  TopicDashboardSubSectionData
} from "../../dashboard/types";
import {
  ChartProps,
  ChartSeriesOptions,
  RawChartSeries
} from "common/components/charts/Chart/types";
import { MhcSeason, MhcTimeSeriesGranularityEnum } from "graphqlApi/types";

import { COUNTIES, STATE_LOCATION_NAME } from "common/constants";
import { countyColors, CountyKeys } from "theme/colors";
import { colorConfig } from "common/components/charts/util/color/config";
import { dateAsTime } from "common/components/charts/util/getMinMaxDates";
import { dateToIsoString, weekStartAndEndDates } from "common/util/date";
import { formatDateByGranularity } from "common/util/formatHelpers";
import { seasonIsAllTime } from "common/util/mhcSeason";
import { filterAndFillTimeSeriesDates } from "common/util/timeSeries";
import {
  formatDateFromUTC,
  getUtcDateFromString,
  getUtcDateNumber,
  normalizeDate
} from "common/util/utcDateFromString";

import { InvestigateChartTitlesProps } from "common/components/charts/Investigate/InvestigateChartTitles";
import { LineChartProps } from "common/components/charts/LineChart";
import { formatDateTime } from "common/components/ReportDates/ReportDates";
import { SeasonOption } from "modules/Topics/components/TopicDashboard/SeasonSelector";
import { TopicDashboardChartProps } from "modules/Topics/components/TopicDashboardChart";

/**
 * Converts seasons as MhcSeason[] to SeasonOption[] for season picker / select input
 *
 * @param _seasons - list of seasons as MhcSeason
 * @returns list of season options for picker / select input
 *
 * @see MhcSeason
 * @see SeasonOption
 */
export const getSeasonOptions = (_seasons?: MhcSeason[]): SeasonOption[] => {
  if (!_seasons?.length) return [];
  const seasons: MhcSeason[] = _seasons;
  return (
    sortBy(seasons, ({ startDate }) => (getUtcDateNumber(startDate) as number) * -1)?.map(
      ({ slug, startDate, endDate, name }) => {
        const formattedStartDate =
          formatDateTime(
            getUtcDateFromString(startDate ?? null) ?? undefined,
            MhcTimeSeriesGranularityEnum.Month,
            undefined,
            "short"
          ) ?? "";
        const formattedEndDate =
          formatDateTime(
            getUtcDateFromString(endDate ?? null) ?? undefined,
            MhcTimeSeriesGranularityEnum.Month,
            undefined,
            "short"
          ) ?? "";
        return {
          id: slug,
          title: name?.includes("All Time")
            ? name
            : `Fall and Winter Virus Season (${formattedStartDate} - ${formattedEndDate})`
        } as SeasonOption;
      }
    ) ?? []
  );
};

type GetCovidSeasonLineChartPropsFromSubSectionParams = {
  locationName: string;
  section: TopicDashboardSubSectionData | null;
  chartId: string;
  season: MhcSeason;
  formatSeries?: (series: RawChartSeries[], seasonDates?: string[]) => ChartSeriesOptions[];
  titleProps?: Partial<InvestigateChartTitlesProps>;
  granularity?: MhcTimeSeriesGranularityEnum;
};
export const getCovidSeasonLineChartPropsFromSubSection = ({
  locationName,
  section,
  chartId,
  season,
  formatSeries,
  titleProps,
  granularity = "week"
}: GetCovidSeasonLineChartPropsFromSubSectionParams): TopicDashboardChartProps => {
  const group = section?.chartGroup;
  const chart = group?.charts.find(({ id }) => id === chartId);
  const props = chart?.props as LineChartProps | null;
  if (!props) {
    return {
      chartProps: null,
      chartTitleProps: null
    };
  }
  const isAllTime = seasonIsAllTime(season);
  const firstSeries = props.series?.[0] ?? { dates: [] };
  const convertedDates = firstSeries.dates as string[];
  let seasonDates = firstSeries.dates ?? [];
  if (season?.startDate && season?.endDate) {
    seasonDates = filterAndFillTimeSeriesDates({
      dates: convertedDates,
      startsOn: season?.startDate,
      endsOn: isAllTime ? (seasonDates[seasonDates.length - 1] as string) : season?.endDate,
      granularity: MhcTimeSeriesGranularityEnum.Week
    }).map((d) => dateToIsoString(d));
  }

  const chartProps = {
    ...props,
    height: isAllTime ? 500 : 400,
    statCaption: props.subtitle,
    useRangeSelector: isAllTime,
    useNavigator: isAllTime,
    useScrollbar: isAllTime,
    useRangeSelectorButtons: isAllTime,
    xAxisEnhancements: {
      ...props.xAxisEnhancements,
      overrideXDateFormatter: ({ date }) => {
        if (!date) return "";
        const { granularity } = props;
        if (granularity === MhcTimeSeriesGranularityEnum.Week) {
          return weekStartAndEndDates({
            end: new Date(formatDateFromUTC(date) as string),
            formatAsString: true
          }) as string;
        } else {
          return formatDateByGranularity({ value: normalizeDate(new Date(date)), granularity });
        }
      }
    },
    xAxisOptions: {
      tickmarkPlacement: "on",
      dateTimeLabelFormats: dateTimeLabelFormats,
      labels: {
        enabled: true,
        style: {
          color: "black"
        },
        formatter: ({ value }) => {
          return formatDateFromUTC(value, granularity, undefined, "short") ?? `${value}`;
        }
      }
    },
    series:
      formatSeries?.(props.series, seasonDates) ??
      props.series.map(({ dates = [], values = [], ...series }, i): ChartSeriesOptions => {
        const valueMap: Record<string, number | null> = {};
        dates.forEach((d: string, i) => (valueMap[d] = values[i] as number | null));
        return {
          ...series,
          color: colorConfig.set[i],
          data: seasonDates?.map((d: string) => [dateAsTime(d), valueMap[d] ?? null]),
          type: "line",
          marker: {
            enabled: dates.length <= 50,
            radius: 5,
            symbol: "circle"
          }
        };
      })
  } as ChartProps;

  return {
    chartProps,
    chartTitleProps: {
      title: props.title,
      subtitle: props.subtitle,
      locationName,
      ...titleProps
    },
    attributions: chart?.attributions ?? []
  };
};

export const chartableLocations: TopicDashboardChartSectionConfig["locations"] = [
  ...Object.values(COUNTIES),
  { id: "state", name: STATE_LOCATION_NAME }
];

type ChartConfigsForLocationComparisonParams = {
  id: string;
  title?: string;
  identifier: string;
};
export const chartConfigsForLocationComparison = ({
  id,
  title,
  identifier
}: ChartConfigsForLocationComparisonParams): TopicDashboardChartSectionConfig[] => {
  return [
    {
      id,
      title: title ?? "",
      type: "location-comparison",
      includeStratifications: false,
      stratificationsAsDropdown: true,
      locations: chartableLocations,
      statIdConfigs: chartableLocations
        .map(({ id }) => id)
        .map((forLocationId) => ({
          identifier,
          forLocationId,
          needSeries: true
        }))
    }
  ];
};

type FormatCountySeriesParams = {
  series: RawChartSeries[];
  seasonDates?: string[];
  locationId: string;
};
export const formatCountySeries = ({
  series,
  seasonDates,
  locationId
}: FormatCountySeriesParams): ChartSeriesOptions[] => {
  return series.map(({ id = "", dates = [], values = [], ...series }, i): ChartSeriesOptions => {
    const isCounty = id.startsWith("county-");
    const colorKey = id.replace("county-", "") as CountyKeys;
    const valueMap: Record<string, number | null> = {};
    dates.forEach((d: string, i) => (valueMap[d] = values[i] as number | null));
    return {
      ...series,
      color: (isCounty ? countyColors[colorKey] : colorConfig.set[0]) ?? colorConfig.set[i],
      data: seasonDates?.map((d: string) => [dateAsTime(d), valueMap[d] ?? null]),
      dashStyle: id === locationId ? undefined : "Dash",
      type: "line",
      lineWidth: isCounty ? 2 : 3,
      zIndex: isCounty ? 3 : 10,
      marker: {
        enabled: seasonDates && seasonDates.length < 100,
        radius: 5,
        symbol: "circle"
      }
    };
  });
};

type GetDataDatePeriodParams = {
  start?: string;
  end?: string | null;
  isWeekly?: boolean;
  granularity?: MhcTimeSeriesGranularityEnum;
  asParenthetical?: boolean;
  addTimeFrameLabel?: boolean;
};
export const getDataDatePeriod = ({
  start,
  end,
  granularity,
  asParenthetical = true,
  addTimeFrameLabel = false
}: GetDataDatePeriodParams) => {
  let dataDatePeriod = "";
  let startDate = "";
  let endDate = "";
  let startDateObj = null;
  let endDateObj = null;
  const isWeekly = granularity === MhcTimeSeriesGranularityEnum.Week;
  if (start) {
    startDateObj = getUtcDateFromString(start) as Date;
    startDate = formatDateByGranularity({
      value: startDateObj,
      granularity
    });
  }
  if (end) {
    endDateObj = getUtcDateFromString(end) as Date;
    endDate = formatDateByGranularity({
      value: endDateObj,
      granularity
    }) as string;
  }
  if (!startDateObj && endDateObj && isWeekly) {
    startDate = formatDateByGranularity({
      value: sub(endDateObj, { days: 6 }),
      granularity
    });
  }
  const dates = compact([startDate, endDate]);
  if (dates.length > 1) {
    dataDatePeriod = `${asParenthetical ? "(" : ""}${dates.join(" - ")}${
      asParenthetical ? ")" : ""
    }`;
  } else dataDatePeriod = dates[0] as string;
  if (addTimeFrameLabel) {
    dataDatePeriod = `Last ${granularity ?? "week"} ${dataDatePeriod}`;
  }
  return dataDatePeriod;
};
